<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <b-modal
          id="collapseModals"
          v-model="hasLegal"
          title="PERSONAL DATA PROTECTION CONTRACT"
          :cancel-disabled="true"
          :ok-disabled="okDisabled"
          :no-close-on-backdrop="true"
          :no-close-on-esc="true"
          :hide-header-close="true"
          :ok-only="true"
          @ok="handleOk"
        >
          <h4>Read and accept</h4>
          <div
            class="legal__content__modal"
            @scroll="onScroll"
          >
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Harum, at facilis non, odio est ut commodi ex tenetur deleniti numquam quos asperiores eligendi, ipsam consequatur dicta sapiente saepe dolores animi.</p>
            <p>Dolorum aliquam, officiis dolorem quo, atque similique id optio sit libero quasi, aut aspernatur? Nihil accusantium enim unde, fugiat culpa in optio totam alias atque, officia distinctio possimus aliquid quam?</p>
            <p>Illum odit unde eos reiciendis ea, inventore nostrum cumque fuga cupiditate deserunt repudiandae beatae voluptate id consequatur laborum quaerat ducimus omnis fugiat? Odit, dolor minus deserunt quo molestias dolorem vel.</p>
            <p>Dolorem voluptatem alias praesentium odit quidem officiis similique minus suscipit provident sapiente reiciendis, amet tempora, ad tenetur vel dolores quod animi quas? Tenetur nulla fugiat, alias ea laudantium facilis libero?</p>
            <p>Quam, explicabo at. Culpa qui unde ratione quia error ducimus ullam, non ab. Doloremque enim autem, eaque perspiciatis neque error non molestiae facilis. Nisi voluptatibus reiciendis similique qui aliquam perferendis.</p>
            <p>Minima optio qui ullam distinctio praesentium itaque deleniti quaerat quae incidunt aliquid reprehenderit, adipisci enim officiis, suscipit repudiandae dignissimos in laudantium eos recusandae obcaecati non blanditiis magnam esse doloribus! Illo.</p>
            <p>Maiores, dolores. Eaque provident cum blanditiis, optio doloribus ullam numquam quos beatae ut aspernatur, soluta quidem voluptate et ipsa. Odio, provident doloribus? Cumque perferendis iusto veniam perspiciatis assumenda asperiores expedita.</p>
            <p>Sit nam vel, amet harum soluta nostrum voluptas odit ducimus? Ipsa sint, hic ipsum vel officia rem nesciunt accusantium veritatis explicabo quas placeat? Iure neque, inventore quas ut eaque distinctio?</p>
            <p>Nam asperiores alias fugit dicta, quas tempore modi nostrum facere. Dolore at in quo ex nam ad esse earum incidunt atque sed assumenda eius fugiat, recusandae magnam sint doloremque nesciunt?</p>
            <p>Temporibus, ratione architecto ut laudantium ab eaque, dolores repellendus inventore cumque illo ea hic veritatis labore molestiae atque sed? Error, ad minima vel dignissimos ex repellat deleniti nisi accusantium? Velit.</p>
            <p>Eius a architecto est iusto, officiis provident sed dignissimos atque quos qui enim sequi ipsam optio delectus modi fuga eaque recusandae dolorum consectetur obcaecati odit pariatur natus id quasi! Vel.</p>
            <p>Cum dolores aspernatur inventore qui quisquam dolorum corrupti provident non incidunt tempore vitae odio atque deserunt nulla blanditiis, fugit adipisci earum reprehenderit laborum quae error natus molestias. Autem, laboriosam qui!</p>
            <p>Voluptatum reiciendis voluptates non aspernatur ipsam architecto distinctio nisi blanditiis possimus qui iusto, at unde provident similique rerum cumque sapiente hic ipsum. Cum placeat ex consequuntur quam asperiores, modi incidunt?</p>
            <p>Culpa natus autem repudiandae quas placeat, labore numquam repellat doloremque eaque voluptatum libero dolorum necessitatibus assumenda molestiae nulla. Distinctio, est. Recusandae possimus aliquid sequi molestias dicta. Aliquam laudantium quas odio!</p>
            <p>Quaerat, quo. Ab, magni. Quia in, vel similique possimus sint facilis, consectetur laudantium accusantium obcaecati nemo architecto perferendis quod non blanditiis at quae officiis deserunt, praesentium dicta nulla. Officiis, reprehenderit.</p>
            <p>Obcaecati consectetur nulla modi accusamus. Quasi, facilis vel nihil deserunt ipsa perspiciatis illum quam possimus doloribus modi assumenda laborum molestiae blanditiis adipisci similique eos recusandae! Reprehenderit quasi natus quam temporibus.</p>
            <p>Omnis aspernatur veniam laborum debitis iure nihil repellendus repellat nulla a dignissimos? Non dignissimos maiores consectetur aperiam similique laboriosam repellat expedita, iure repellendus nobis corporis enim at cum veritatis quaerat.</p>
            <p>Aliquam facilis, vel ut alias quaerat rem enim iure quisquam, incidunt iste qui, aperiam velit vitae id? Quaerat, facere. Delectus veniam corrupti ut voluptates mollitia! Eaque consequuntur aut quidem praesentium.</p>
            <p>Eos earum error officia et nemo? Tenetur, veritatis nisi ex perferendis eligendi fugit obcaecati soluta nihil eaque voluptatum, minima odit ullam excepturi quaerat magni optio doloremque laborum. Doloribus, magnam recusandae.</p>
            <p>Exercitationem, numquam cumque. Dolorem, nobis nemo molestias repudiandae excepturi eaque distinctio fugit deserunt recusandae saepe beatae temporibus cum? Minima ab tempora possimus repellendus accusamus, explicabo odit veritatis rerum praesentium ad.</p>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  data() {
    return {
      reading_time: 0,
      okDisabled: true,
    }
  },
  computed: {
    ...mapGetters({
      hasLegal: 'users/hasLegal',
    }),
  },
  async mounted() {
    setInterval(() => {
      this.reading_time += 0.1
    }, 100)
    await this.$store.dispatch('users/checkEvaluatorData')
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.okDisabled = false
      }
    },
    async handleOk() {
      await this.$store.dispatch('users/saveEvaluatorData', { reading_time: this.reading_time })
      this.$toast.success('Saved legal data')
    },
  },
}
</script>
